import React, { useMemo } from 'react'
import { Image } from 'react-native'

function Img({ source, ...otherProps }) {
  const image = useMemo(
    () => <Image source={source} {...otherProps} />,
    [source]
  )
  return image
}

export default Img
