const colors = {
  primary: '#037ECF',
  secondary: '#4C9ACD',
  accent: '#ED7409', //'#FFC478',
  border: '#DEDEDE',
  lightaccent: '#F0E5D8',
  light: '#D8E7F0',
  lighter: '#F6F4F4',
  bg: '#FFFFFF',
}

const padding = {
  xs: 4,
  s: 8,
  m: 16,
  l: 24,
}

const fontSize = {
  s: 13,
  m: 15,
  l: 17,
  xl: 19,
  xxl: 24,
}

// die Safe area innerhalb des Screens, damit die Top-Bar nicht überlappt
const storySafeArea = {
  top: 80,
  bottom: 50,
}

const borderRadius = padding.xs
const maxWidth = 480

const boxShadow = {
  m: { boxShadow: '0 5px 20px rgba(0,0,0,0.2)' },
}

const button = {
  paddingVertical: padding.m,
  paddingHorizontal: padding.l,
  borderRadius: 4,
  borderColor: colors.primary,
  borderWidth: 1,
  margin: padding.s,
}

const buttonText = {
  color: colors.primary,
  fontSize: fontSize.m,
  fontWeight: 'bold',
}

const global = {
  // Layout
  container: {
    flex: 1,
    backgroundColor: colors.bg,
    alignItems: 'center',
    justifyContent: 'center',
  },
  maxWidth: {
    width: '100%',
    alignItems: 'center',
    maxWidth,
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  listEntry: {
    width: '100%',
    padding: padding.m,
    borderBottomColor: colors.border,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  story: {
    alignItems: 'center',
  },
  // Form
  input: {
    width: '100%',
    borderColor: colors.border,
    borderWidth: 1,
    borderRadius,
    padding: padding.m,
    margin: padding.s,
    fontSize: fontSize.m,
  },
  // Button
  button,
  buttonPrimary: {
    ...button,
    backgroundColor: colors.primary,
  },
  buttonDisabled: {
    ...button,
    backgroundColor: colors.light,
    borderColor: colors.light,
    opacity: 0.5,
  },
  buttonText,
  buttonPrimaryText: {
    ...buttonText,
    color: colors.bg,
  },
  // Text
  textS: {
    fontSize: fontSize.s,
  },
  text: {
    fontSize: fontSize.m,
  },
  textL: {
    fontSize: fontSize.l,
  },
  title: {
    fontSize: fontSize.l,
    fontWeight: 'bold',
  },
  titleXL: {
    fontSize: fontSize.xl,
    fontWeight: 'bold',
  },
  textCenter: { textAlign: 'center' },
  textBox: {
    marginVertical: padding.m,
  },
}

export { colors, padding, fontSize, boxShadow, global, storySafeArea }
