import React from 'react'
import { Pressable, Text, View } from 'react-native'
import Story from './Story'
import Quiz from './Quiz'
import { colors } from '../../styles/global'

function Lesson({ lesson, isLocked = false }) {
  // todo: need a preview img
  const { quiz, stories } = lesson
  const StoryList = () =>
    stories.map((story, index) => {
      return (
        <Story
          key={index}
          title={story.title}
          thumbnail={story.thumbnail}
          progress={story.progress}
          id={story.id}
          isLocked={isLocked}
        />
      )
    })
  return (
    <View style={[{ width: '100%', alignItems: 'center' }]}>
      <StoryList />
      <Quiz
        title={quiz.title}
        thumbnail={quiz.thumbnail}
        progress={quiz.progress}
        id={quiz.id}
        isLocked={isLocked}
      />
    </View>
  )
}

export default Lesson
