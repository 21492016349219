import React, { useState, useRef, useEffect, useMemo, memo } from 'react'
import {
  useSharedValue,
  cancelAnimation,
  withTiming,
  Easing,
  runOnJS,
} from 'react-native-reanimated'
import FullscreenPressable from './FullscreenPressable'
import StoryBackground from './StoryBackground'
import StoryControls from './StoryControls'
import StoryOverlay from './StoryOverlay'

const defaultDuration = 4000

function StorySegment({
  id,
  thumbnail,
  segment = {},
  segments = [], // alle Segmente
  goToNextSegment = null,
  goToPrevSegment = null,
  currentSegment,
  setCurrentSegment,
  close,
}) {
  const isVideo = segment.vid ?? false
  const [isPlaying, setIsPlaying] = useState(false)
  const [segmentIsOver, setSegmentIsOver] = useState(!isVideo)
  const video = useRef(null)

  const currentSegmentProgress = useSharedValue(0)

  const hasAudio = segment.vid && segment.vid.has_audio

  const startPlayback = () => {
    console.log('play segment')
    setIsPlaying(true)
    video.current?.playAsync()
  }

  const pausePlayback = () => {
    console.log('pause segment')
    setIsPlaying(false)
    video.current?.pauseAsync()
  }

  const onPlaybackStatusUpdate = (status) => {
    if (status.isLoaded && status.isPlaying) {
      currentSegmentProgress.value =
        status.positionMillis / status.durationMillis
    }
    if (status.didJustFinish) {
      setSegmentIsOver(true)
      currentSegmentProgress.value = 1
    }
  }

  const storyBackgroundMemo = useMemo(
    () => (
      <StoryBackground
        bgColor={segment.bg_color}
        img={segment.img}
        vid={segment.vid}
        vidRef={video}
        onPlaybackStatusUpdate={onPlaybackStatusUpdate}
      />
    ),
    [] // Memoization, um Rerender zu vermeiden, die das Video ruckeln lassen
  )

  const storyOverlayMemo = useMemo(
    () => (
      <StoryOverlay
        key={segment.index}
        segment={segment}
        infoDismissAction={startPlayback}
        infoShowAction={pausePlayback}
      />
    ),
    []
  )

  return (
    <>
      {storyBackgroundMemo}
      <FullscreenPressable
        pausePlayback={pausePlayback}
        startPlayback={startPlayback}
        goToNextSegment={goToNextSegment}
        goToPrevSegment={goToPrevSegment}
        video={video}
      />
      <StoryControls
        id={id}
        thumbnail={thumbnail}
        segment={segment}
        segments={segments}
        currentSegment={currentSegment}
        currentSegmentProgress={currentSegmentProgress}
        setCurrentSegment={setCurrentSegment}
        isPlaying={isPlaying}
        startPlayback={startPlayback}
        pausePlayback={pausePlayback}
        segmentIsOver={segmentIsOver}
        goToNextSegment={goToNextSegment}
        close={close}
      />
      {storyOverlayMemo}
    </>
  )
}

export default memo(StorySegment)
