import React from 'react'
import { Pressable, View } from 'react-native'
import Animated, { useAnimatedStyle } from 'react-native-reanimated'
import { boxShadow, colors } from '../../styles/global'

function NavBarSegmentVideo({
  index,
  currentSegment,
  currentSegmentProgress,
  setCurrentSegment = () => null,
}) {
  const currentSegmentProgressStyle = useAnimatedStyle(() => {
    return {
      width: `${currentSegmentProgress.value * 100}%`,
    }
  })
  return (
    <Pressable
      onPress={() => setCurrentSegment(i)}
      style={{
        flex: 1,
        height: 40,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 4,
      }}
    >
      <View
        style={{
          flex: 1,
          height: 8,
          borderRadius: 4,
          overflow: 'hidden',
          backgroundColor: index < currentSegment ? colors.bg : 'gray',
        }}
      >
        {currentSegment === index && (
          <Animated.View
            style={[
              { height: '100%', backgroundColor: 'white' },
              currentSegmentProgressStyle,
            ]}
          />
        )}
      </View>
    </Pressable>
  )
}

export default NavBarSegmentVideo
