import React, { memo, useEffect, useMemo } from 'react'
import { View } from 'moti'
import { Pressable, Text } from 'react-native'
import MultipleChoice from './MultipleChoice'
import styles from './styles'
import StoryInfoBox from './StoryInfoBox'

function StoryOverlay({
  segment,
  infoDismissAction = () => null,
  infoShowAction = () => null,
}) {
  const { content } = segment
  const components = []

  if (segment.infotext) {
    components.push(
      <StoryInfoBox
        key='info'
        text={segment.infotext}
        dismissAction={infoDismissAction}
        showAction={infoShowAction}
      />
    )
  } else {
    infoDismissAction() // die Dismiss-Action (i.d.R. Abspielen) einmal ausführen, wenn es keine Infobox gibt
  }

  // content.forEach((layout, index) => {
  //   const type = layout['acf_fc_layout']
  //   switch (type) {
  //     case 'link':
  //       const { title, url } = layout.link
  //       components.push(
  //         <View
  //           key={index}
  //           style={[styles.storySegmentWrap, { alignSelf: 'flex-end' }]}
  //           pointerEvents='box-none'
  //         >
  //           <Pressable
  //             style={styles.storyButton}
  //             onPress={() => Linking.openURL(url)}
  //           >
  //             <Text style={[styles.title, { textAlign: 'center' }]}>
  //               {title}
  //             </Text>
  //           </Pressable>
  //         </View>
  //       )
  //       break
  //     case 'multiple_choice':
  //       components.push(
  //         <View
  //           key={index}
  //           style={styles.storySegmentWrap}
  //           pointerEvents='box-none'
  //         >
  //           <MultipleChoice choices={layout.question} />
  //         </View>
  //       )
  //       break
  //     case 'text':
  //       const { position } = layout
  //       const alignSelf =
  //         position === 'center'
  //           ? 'center'
  //           : position === 'bottom'
  //           ? 'flex-end'
  //           : 'flex-start'
  //       components.push(
  //         <Text
  //           key={index}
  //           style={[
  //             styles.storySegmentWrap,
  //             {
  //               fontSize: 20,
  //               width: '100%',
  //               color: 'white',
  //               textAlign: 'center',
  //               margin: 'auto',
  //               alignSelf,
  //             },
  //           ]}
  //           pointerEvents='none'
  //         >
  //           {layout.text}
  //         </Text>
  //       )
  //       break
  //     default:
  //       return null
  //   }
  // })
  return (
    <View style={styles.overlayContainer} pointerEvents='box-none'>
      {components}
    </View>
  )
}

export default memo(StoryOverlay)
