import React from 'react'
import { Pressable, Text, View, Image } from 'react-native'
import Svg, { Circle } from 'react-native-svg'
import { useNavigation } from '@react-navigation/native'
import styles from './styles'
import { colors } from '../../styles/global'
import Img from '../Shared/Img'
import Ionicons from '@expo/vector-icons/Ionicons'

const CIRCLE_RADIUS = 44

function Quiz({ title, id, thumbnail, progress, isLocked = false }) {
  const navigation = useNavigation()
  const quizResult = parseInt(progress) // to integer
  const quizIsOver = quizResult > 0
  return (
    <Pressable
      style={styles.quiz}
      onPress={() => {
        isLocked ? null : navigation.navigate('Quiz', { id }) // todo: display error
      }}
    >
      <View>
        <Svg
          height='100'
          width='100'
          viewBox='0 0 100 100'
          style={{
            transformOrigin: 'center',
            transform: [{ rotateZ: '-90deg' }],
          }}
        >
          {!quizIsOver && (
            <Circle
              cx='50'
              cy='50'
              r='48'
              stroke={colors.accent}
              strokeWidth='4'
              strokeLinecap='round'
              strokeDasharray='0, 0'
              fill='transparent'
            />
          )}
          {quizIsOver && (
            <>
              <Circle
                cx='50'
                cy='50'
                r='44'
                stroke={colors.border}
                strokeWidth='8'
                fill='transparent'
              />
              <Circle
                cx='50'
                cy='50'
                r='44'
                stroke={colors.primary}
                strokeWidth='8'
                strokeLinecap='round'
                strokeDasharray={`${
                  (quizResult / 100) * CIRCLE_RADIUS * 2 * Math.PI
                }, ${Math.PI * CIRCLE_RADIUS * 2}`}
                fill='transparent'
              />
            </>
          )}
        </Svg>
        <Img
          style={{
            position: 'absolute',
            left: quizIsOver ? 12 : 6,
            top: quizIsOver ? 12 : 6,
            width: quizIsOver ? 76 : 88,
            height: quizIsOver ? 76 : 88,
            borderRadius: '100%',
            backgroundColor: 'gray',
            opacity: isLocked || quizIsOver ? 0.3 : 1,
          }}
          source={{ uri: thumbnail }}
        />
        {isLocked ||
          (quizIsOver && (
            <View
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {quizIsOver && (
                <Ionicons size={64} name='checkmark' color={colors.primary} />
              )}
              {isLocked && <Text style={{ fontSize: 30 }}>🔒</Text>}
            </View>
          ))}
      </View>
      <Text style={[styles.title, { marginVertical: 8 }]}>{title}</Text>
      <View style={[isLocked ? styles.buttonDisabled : styles.button]}>
        <Text style={styles.buttonText}>
          {quizIsOver ? 'Ergebnis ansehen' : 'Quiz starten'}
        </Text>
      </View>
    </Pressable>
  )
}

export default Quiz
