import React, { useState, useCallback, memo, useMemo } from 'react'
import { Text, View, Pressable, Image, TouchableOpacity } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getStory, postProgress } from '../api/api'
import StorySegment from '../components/StorySegment'
import Loading from '../components/Loading/Loading'
import styles from './styles'
import { useFocusEffect } from '@react-navigation/native'

function Story({ navigation, route }) {
  const { id, index } = route.params
  const [currentSegment, setCurrentSegment] = useState(index ?? 0)
  const story = useQuery(['story', id], () => getStory({ id }))

  const queryClient = useQueryClient()

  useFocusEffect(
    useCallback(() => {
      // Do something when the screen is focused
      return () => {
        // setCurrentSegment(0)
      }
    }, [])
  )

  const progressMutation = useMutation(postProgress, {
    onSuccess: () => {
      queryClient.invalidateQueries() // alle Queries invalidaten, da der neue Progress Auswirkungen auf fast alle Screens haben kann
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const storySegments = story.data?.segments

  if (story.isLoading) {
    return <Loading />
  }

  const close = (args = {}) => {
    const { auto = false } = args
    navigation.canGoBack() // does not always work correctly :/
      ? navigation.goBack()
      : navigation.navigate('HomeTabs')
    if (auto) {
      progressMutation.mutate({
        id,
      })
    }
  }

  const goToNextSegment = () => {
    const newSegment = currentSegment + 1
    if (newSegment < storySegments.length) {
      setCurrentSegment(newSegment)
    } else {
      close({ auto: true }) // todo: gehe zur nächsten Story?
    }
  }

  const goToPrevSegment = () => {
    const newSegment = currentSegment - 1
    if (newSegment >= 0) {
      setCurrentSegment(newSegment)
    } else {
      setCurrentSegment(0) // todo: set play position to 0
    }
  }

  return (
    <>
      <View
        style={[
          {
            flex: 1,
            backgroundColor: 'black',
          },
        ]}
      >
        <StorySegment
          id={id}
          thumbnail={story.data?.thumbnail}
          key={currentSegment}
          segments={storySegments}
          segment={storySegments ? storySegments[currentSegment] : {}}
          goToNextSegment={goToNextSegment}
          goToPrevSegment={goToPrevSegment}
          close={close}
          currentSegment={currentSegment}
          setCurrentSegment={setCurrentSegment}
        />
      </View>
    </>
  )
}

export default memo(Story)
