import React, { useState, useEffect, useContext } from 'react'
import { View, Text, TextInput, Pressable } from 'react-native'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigation } from '@react-navigation/native'

import { getProfile } from '../api/api'
import { removeUserData } from '../api/user'
import styles from './styles'
import ToastContext from '../components/ToastProvider/ToastContext'
import Loading from '../components/Loading'
import Error from '../components/Error'
import LoginView from '../components/LoginView'
import TableRow from '../components/Shared/TableRow'
import { colors } from '../styles/global'
import Table from '../components/Shared/Table'

function Profile({}) {
  const { addToast } = useContext(ToastContext)
  const profile = useQuery('profile', getProfile)
  const navigation = useNavigation()
  const queryClient = useQueryClient()
  if (profile.isLoading) {
    return <Loading />
  } else if (profile.isError) {
    const { status } = profile.error?.response
    return <Error code={status} />
  }

  console.log(profile.data)

  return (
    <View style={[styles.container]}>
      <View style={[styles.maxWidth, { padding: 16 }]}>
        <Table>
          <TableRow
            headerComponent={<Text style={styles.title}>Username</Text>}
            contentComponent={
              <Text style={styles.textL}>
                {profile.data?.data?.display_name}
              </Text>
            }
            isLast
          />
        </Table>
        <Table title='Errungenschaften'>
          <TableRow
            headerComponent={<Text style={styles.title}>Noch nicht</Text>}
            contentComponent={<Text style={styles.textL}>implementiert</Text>}
            isLast
          />
        </Table>
        <Pressable
          style={styles.buttonPrimary}
          onPress={() => {
            removeUserData().then(() => {
              queryClient.invalidateQueries()
              addToast({ message: 'Logout erfolgreich', type: 'success' })
              navigation.navigate('Login')
            })
          }}
        >
          <Text style={styles.buttonPrimaryText}>Logout</Text>
        </Pressable>
      </View>
    </View>
  )
}

export default Profile
