import config from '../config'
const { create } = require('axios').default
import { getUserData } from './user'
import { decode, encode } from 'base-64'

// dummer Polyfill weil axios zickt
if (!global.btoa) {
  global.btoa = encode
}

if (!global.atob) {
  global.atob = decode
}

// define the api
const api = create({
  baseURL: `${config.siteURL}/wp-json/api`,
})

// Username & PW einsetzen
api.interceptors.request.use(
  async (config) => {
    const { username, password } = await getUserData()
    if (username && password) {
      config.auth = { username, password }
      console.log(config.auth)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const getCourses = () => {
  return api.get('/kurse').then((res) => res.data)
}

const getCourse = ({ id }) => {
  return api.get(`/kurse/${id}`).then((res) => {
    return res.data
  })
}

const getStory = ({ id }) => {
  return api.get(`/stories/${id}`).then((res) => res.data)
}

const getQuiz = ({ id }) => {
  return api.get(`/quiz/${id}`).then((res) => res.data)
}

const getBookmarks = () => {
  return api.get(`/bookmarks`).then((res) => res.data)
}

const getLogin = ({ token }) => {
  return api.get(`/login/${token}`).then((res) => res.data)
}

const getProfile = () => {
  return api.get(`/profil`).then((res) => res.data)
}

const getSearch = ({ term }) => {
  return api.get(`/search/${term}`).then((res) => res.data)
}

const getNews = () => {
  return api.get(`/neuigkeiten`).then((res) => res.data)
}

const getArticle = ({ id }) => {
  return api.get(`/neuigkeiten/${id}`).then((res) => res.data)
}

const postAnswer = ({ id, data }) => {
  return api.post(`/antworten/${id}`, data)
}

const postProgress = ({ id }) => {
  // hat zur Zeit kein data Obj
  return api.post(`/progress/${id}`).then((res) => console.log(res.data))
}

const postBookmark = ({ id, data }) => {
  return api.put(`/bookmarks/${id}`, data)
}

const deleteBookmark = ({ id, data }) => {
  return api.delete(`/bookmarks/${id}/${data.index}`).then((res) => {
    console.log(res.data)
  })
}

export {
  getCourses,
  getCourse,
  getStory,
  getQuiz,
  getBookmarks,
  getLogin,
  getProfile,
  getSearch,
  getNews,
  getArticle,
  postAnswer,
  postProgress,
  postBookmark,
  deleteBookmark,
}
