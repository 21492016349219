const devConfig = {
  siteURL: 'http://onboarding-backend.test',
}

const prodConfig = {
  ...devConfig, // zur Sicherheit, falls ich mal einen Key vergesse :D
  siteURL: 'https://onboard.clipvilla.com',
}

const config = __DEV__ ? devConfig : prodConfig

export default config
