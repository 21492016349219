import React from 'react'
import { View, Text, Pressable } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import styles from './styles'
import { colors, padding } from '../../styles/global'
import { useNavigation } from '@react-navigation/native'

const messages = {
  401: 'Du bist nicht eingeloggt.',
  404: 'Der Inhalt wurde nicht gefunden.',
}

function Error({ code = 401 }) {
  const message = messages[code] ?? 'Ein unbekannter Fehler ist aufgetreten.'
  const navigation = useNavigation()

  return (
    // todo: ordentlich machen
    <View
      style={{
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Ionicons
        name='alert-circle'
        color={colors.accent}
        size={64}
        style={{ margin: padding.l }}
      />
      <Text style={styles.textCenter}>{message}</Text>
      {code === 401 && (
        <Pressable
          style={styles.buttonPrimary}
          onPress={() => {
            navigation.navigate('Login')
          }}
        >
          <Text style={styles.buttonPrimaryText}>Zum Login</Text>
        </Pressable>
      )}
    </View>
  )
}

export default Error
