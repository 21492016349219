import React, { useRef, useEffect } from 'react'
import { View, Platform, Text, Pressable } from 'react-native'
import LottieWeb from '../Lottie'

function LoadingSpinner({ style }) {
  return (
    <LottieWeb
      style={style}
      source={require('../../assets/lottie/loading.json')}
    />
  )
}

export default LoadingSpinner
