import React from 'react'
import { View, Text, Pressable, ScrollView } from 'react-native'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import Ionicons from '@expo/vector-icons/Ionicons'

import { getBookmarks, deleteBookmark } from '../api/api'
import Loading from '../components/Loading'
import Error from '../components/Error'
import styles from './styles'
import ListEntry from '../components/Shared/ListEntry'

function Bookmarks({ navigation }) {
  // Queries
  const bookmarks = useQuery('bookmarks', getBookmarks)

  const queryClient = useQueryClient()

  const removeBookmark = useMutation(deleteBookmark, {
    onSuccess: () => {
      queryClient.invalidateQueries(['bookmarks'])
    },
    onError: (err) => {
      console.log(err)
    },
  })

  // Todo: Loading state
  if (bookmarks.isLoading) {
    return <Loading />
  } else if (bookmarks.isError) {
    const { status } = bookmarks.error?.response
    return <Error code={status} />
  }

  const BookmarksList = () => {
    return bookmarks.data?.length > 0
      ? bookmarks.data?.map((bookmark, index) => (
          <ListEntry
            key={index}
            title={`${bookmark.title} / ${parseInt(bookmark.index) + 1}`}
            thumbnail={bookmark.thumbnail}
            onPress={() => {
              navigation.navigate('Story', {
                id: bookmark.id,
                index: bookmark.index,
              })
            }}
            onDeletePress={() =>
              removeBookmark.mutate({
                id: bookmark.id,
                data: { index: bookmark.index },
              })
            }
          />
        ))
      : null
  }

  return (
    <ScrollView style={{ width: '100%', flex: 1 }}>
      <BookmarksList />
    </ScrollView>
  )
}

export default Bookmarks
