import React from 'react'
import { Pressable, View } from 'react-native'
import { boxShadow, colors } from '../../styles/global'

function NavBarSegmentStill({
  index,
  currentSegment,
  setCurrentSegment = () => null,
}) {
  return (
    <Pressable
      onPress={() => setCurrentSegment(i)}
      style={{
        height: 40,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 4,
      }}
    >
      <View
        style={{
          width: 8,
          height: 8,
          borderRadius: 4,
          backgroundColor: index <= currentSegment ? colors.bg : 'gray',
        }}
      />
    </Pressable>
  )
}

export default NavBarSegmentStill
