import React, { useState, useEffect } from 'react'
import { Text, View, Image, Pressable } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import { MotiView, MotiText } from 'moti'
import { useMutation, useQueryClient } from 'react-query'
import { postAnswer } from '../../api/api'
import { colors } from '../../styles/global'
import styles from './styles'
import Animated, {
  cancelAnimation,
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated'

function MultipleChoice({ choices = {}, index = 0 }) {
  const { question, answers, answered, id } = choices
  const boxOpacity = useSharedValue(1)
  const [isLoading, setIsLoading] = useState(false)

  const loadingState = isLoading && !answered

  useEffect(() => {
    if (loadingState) {
      boxOpacity.value = withRepeat(
        withTiming(0.5, { duration: 500 }),
        -1,
        true
      )
    } else {
      boxOpacity.value = 1
    }
  }, [loadingState])

  const loadingBgStyle = useAnimatedStyle(() => {
    return {
      opacity: boxOpacity.value,
    }
  })

  const queryClient = useQueryClient()
  // Mutations
  const mutation = useMutation(postAnswer, {
    onSuccess: () => {
      queryClient.invalidateQueries(['story'])
      queryClient.invalidateQueries(['quiz'])
    },
    onError: (err) => {
      console.log(err)
    },
  })
  const answerComponents = []
  answers?.forEach((answer, i) => {
    let answerStyle = []
    let answerCircleStyle = []
    if (answered) {
      const correctStyle = answer.is_correct ? styles.answerCorrect : {}
      const incorrectStyle = !answer.is_correct ? styles.answerIncorrect : {}
      const pickedStyle = answer.picked ? styles.answerPicked : {}
      const correctCircleStyle = answer.is_correct
        ? styles.answerCircleCorrect
        : {}
      const incorrectcorrectCircleStyle = !answer.is_correct
        ? styles.answerCircleIncorrect
        : {}
      answerCircleStyle = [correctCircleStyle, incorrectcorrectCircleStyle]
      answerStyle = [correctStyle, incorrectStyle, pickedStyle]
    }
    const Icon = () => (
      <Ionicons
        name={answer.is_correct ? 'checkmark-outline' : 'close-outline'}
        size={24}
        color='white'
      />
    )
    answerComponents.push(
      <Pressable
        key={`${index}-${i}`}
        style={[styles.answer, ...answerStyle]}
        onPress={() => {
          mutation.mutate({
            id,
            data: { answer_index: i, answer: answer.answer },
          })
          setIsLoading(true)
        }}
      >
        <View style={[styles.answerCircle, ...answerCircleStyle]}>
          {answered && <Icon />}
        </View>
        <Text style={styles.textL}>{answer.answer}</Text>
      </Pressable>
    )
  })
  return (
    <MotiView
      from={{ opacity: 0, translateY: 30 }}
      animate={{ opacity: 1, translateY: 0 }}
      style={[styles.multipleChoice, { backgroundColor: colors.bg }]}
    >
      <Animated.View
        style={[{ width: '100%' }, loadingBgStyle]}
        pointerEvents={answered ? 'none' : 'auto'}
      >
        <Text style={[styles.titleXL, { marginVertical: 16 }]}>{question}</Text>
        {answerComponents}
      </Animated.View>
    </MotiView>
  )
}

export default MultipleChoice
