import React, { useState, useEffect } from 'react'
import { Text, View, ScrollView, Pressable, Image } from 'react-native'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getQuiz, postProgress } from '../api/api'
import Svg, { Circle } from 'react-native-svg'
import Loading from '../components/Loading/Loading'
import MultipleChoice from '../components/Shared/MultipleChoice'
import styles from './styles'
import Animated, {
  useSharedValue,
  withTiming,
  Easing,
  useAnimatedStyle,
  createAnimatedComponent,
  useAnimatedProps,
} from 'react-native-reanimated'
import { colors } from '../styles/global'
import { MotiView } from 'moti'

const CIRCLE_RADIUS = 80
const CIRCLE_STROKE = 15
const CIRCLE_OFFSET = CIRCLE_RADIUS + CIRCLE_STROKE
const CIRCLE_DIMENSIONS = CIRCLE_RADIUS * 2 + CIRCLE_STROKE * 2

const AnimatedCircle = Animated.createAnimatedComponent(Circle)

function Quiz({ navigation, route }) {
  const { id } = route.params
  const [quizIsOver, setQuizIsOver] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(-1)
  const progressAnim = useSharedValue(0)
  const quizResultAnim = useSharedValue(0)

  const queryClient = useQueryClient()

  const quiz = useQuery(['quiz', id], () => getQuiz({ id }))

  const quizResult = quiz.data ? parseInt(quiz.data?.progress) : 0

  const questions = quiz.data?.questions
  const isAnswered =
    currentQuestion >= 0 ? questions[currentQuestion]?.answered : false

  const progress =
    currentQuestion >= 0
      ? (currentQuestion + (isAnswered ? 1 : 0)) / quiz.data?.questions?.length
      : 0

  console.log(Easing)

  useEffect(() => {
    if (!quiz.isLoading) navigation.setOptions({ title: quiz.data?.title })
  }, [quiz.isLoading])

  useEffect(() => {
    progressAnim.value = withTiming(progress, {
      duration: 350,
      easing: Easing.out(Easing.ease),
    })
  }, [progress])

  useEffect(() => {
    if (quizIsOver) {
      quizResultAnim.value = withTiming(quizResult, {
        duration: 1000,
        easing: Easing.out(Easing.ease),
      })
    }
  }, [quizResult, quizIsOver])

  const animatedCircleProps = useAnimatedProps(() => {
    return {
      strokeDasharray: `${
        (quizResultAnim.value / 100) * CIRCLE_RADIUS * 2 * Math.PI
      }, ${Math.PI * CIRCLE_RADIUS * 2}`,
    }
  })

  const progressBarStyle = useAnimatedStyle(() => {
    return { width: `${progressAnim.value * 100}%` }
  })

  const progressMutation = useMutation(postProgress, {
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
    onError: (err) => {
      console.log(err)
    },
  })

  // Todo: Loading state
  if (quiz.isLoading) {
    return <Loading />
  }

  const finishQuiz = () => {
    progressMutation.mutate({
      id: quiz.data.id,
    })
    setQuizIsOver(true)
  }

  const nextQuestion = () => {
    const newQuestion = currentQuestion + 1
    if (newQuestion < quiz.data.questions.length) {
      isAnswered || currentQuestion >= -1
        ? setCurrentQuestion(newQuestion)
        : null
    } else {
      finishQuiz()
    }
  }

  const prevQuestion = () => {
    const newQuestion = currentQuestion - 1
    if (newQuestion >= -1) {
      setCurrentQuestion(newQuestion)
    }
  }

  return (
    <View style={[styles.container, { flex: 1 }]}>
      {currentQuestion === -1 && (
        <View style={{ alignItems: 'center' }}>
          <Image
            style={{ width: 180, height: 180, borderRadius: 90 }}
            source={{ uri: quiz.data?.thumbnail }}
          />
          <Text style={[styles.title, styles.textBox]}>
            Hier könnte dein Disclaimer stehen!
          </Text>
          <Pressable
            style={[styles.buttonPrimary, { marginVertical: 8 }]}
            onPress={() => nextQuestion()}
          >
            <Text style={styles.buttonPrimaryText}>Quiz starten</Text>
          </Pressable>
          <Pressable onPress={() => navigation.goBack()}>
            <Text style={[styles.text, { padding: 16 }]}>Abbrechen</Text>
          </Pressable>
        </View>
      )}
      {currentQuestion >= 0 && !quizIsOver && (
        <>
          <ScrollView
            style={{ width: '100%', flex: 1 }}
            contentContainerStyle={{ alignItems: 'center' }}
          >
            <MultipleChoice
              key={currentQuestion}
              choices={questions[currentQuestion]}
            />
          </ScrollView>
          <View style={{ width: '100%', padding: 20, alignItems: 'center' }}>
            <View style={{ flexDirection: 'row' }}>
              <Pressable
                style={[styles.button, { marginVertical: 28 }]}
                onPress={() => prevQuestion()}
              >
                <Text style={styles.buttonText}>Zurück</Text>
              </Pressable>
              <Pressable
                style={[
                  isAnswered ? styles.buttonPrimary : styles.buttonDisabled,
                  { marginVertical: 28 },
                ]}
                pointerEvents={isAnswered ? 'auto' : 'none'}
                onPress={() => nextQuestion()}
              >
                <Text
                  style={
                    isAnswered ? styles.buttonPrimaryText : styles.buttonText
                  }
                >
                  {progress !== 1 ? 'Weiter' : 'Ergebnis'}
                </Text>
              </Pressable>
            </View>
            <View style={styles.progressBarBG}>
              <Animated.View style={[styles.progressBar, progressBarStyle]} />
            </View>
          </View>
        </>
      )}
      {quizIsOver && (
        <>
          <MotiView
            from={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              width: CIRCLE_DIMENSIONS,
              height: CIRCLE_DIMENSIONS,
            }}
          >
            <Svg
              height={CIRCLE_DIMENSIONS}
              width={CIRCLE_DIMENSIONS}
              viewBox={`0 0 ${CIRCLE_DIMENSIONS} ${CIRCLE_DIMENSIONS}`}
              style={{
                position: 'absolute',
                transformOrigin: 'center',
                transform: [{ rotateZ: '-90deg' }],
              }}
            >
              <Circle
                cx={CIRCLE_OFFSET}
                cy={CIRCLE_OFFSET}
                r={CIRCLE_RADIUS}
                stroke={colors.light}
                strokeWidth='10'
                strokeLinecap='round'
                strokeDasharray='0, 0'
                fill='transparent'
              />
              {quizResult > 0 && (
                <AnimatedCircle
                  cx={CIRCLE_OFFSET}
                  cy={CIRCLE_OFFSET}
                  r={CIRCLE_RADIUS}
                  stroke={colors.primary}
                  strokeWidth='10'
                  strokeLinecap='round'
                  fill='transparent'
                  animatedProps={animatedCircleProps}
                />
              )}
            </Svg>
            <MotiView
              from={{ opacity: 0, translateY: 30 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ delay: 1000 }}
            >
              <Text style={[styles.title, { fontSize: 28 }]}>
                {quizResult}%
              </Text>
            </MotiView>
          </MotiView>
          <View>
            <Pressable
              style={[styles.buttonPrimary]}
              onPress={() => navigation.goBack()}
            >
              <Text style={styles.buttonPrimaryText}>Beenden</Text>
            </Pressable>
          </View>
        </>
      )}
    </View>
  )
}

export default Quiz
