import React, { useEffect, useState } from 'react'
import { View, Text, ScrollView } from 'react-native'
import { useQuery } from 'react-query'
import { getCourse } from '../api/api'
import Lesson from '../components/Lesson/Lesson'
import Loading from '../components/Loading'
import Error from '../components/Error'

const Course = ({ navigation, route }) => {
  const { id } = route.params
  // Queries
  const course = useQuery(['course', id], () => getCourse({ id }))

  useEffect(() => {
    if (!course.isLoading) navigation.setOptions({ title: course.data?.title })
  }, [course.isLoading])

  // Todo: Loading state
  if (course.isLoading) {
    return <Loading />
  } else if (course.isError) {
    const { status } = course.error?.response
    return <Error code={status} />
  }

  const lessons = course.data?.lessons
  const LessonList = () => {
    return lessons.map((lesson, index) => {
      let isLocked = false
      const previousLesson = lessons[index - 1]
      if (previousLesson?.quiz && previousLesson.quiz?.progress <= 0)
        isLocked = true // wenn das vorige Quiz nicht abgeschlossen wurde, sind nachfolgende Lektionen gesperrt
      return <Lesson key={index} lesson={lesson} isLocked={isLocked} />
    })
  }
  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{ alignItems: 'center' }}
    >
      <LessonList />
    </ScrollView>
  )
}

export default Course
