import { StyleSheet } from 'react-native'
import { global, colors } from '../styles/global'

const styles = StyleSheet.create({
  ...global,
  progressBarBG: {
    backgroundColor: colors.light,
    height: 10,
    width: '100%',
    borderRadius: 5,
  },
  progressBar: {
    backgroundColor: colors.primary,
    height: '100%',
    borderRadius: 5,
  },
})

export default styles
