import React, { useState, useEffect, useContext } from 'react'
import { View, Text, TextInput, Pressable } from 'react-native'
import styles from './styles'
import { getLogin } from '../../api/api'
import { setUserData } from '../../api/user'
import ToastContext from '../ToastProvider/ToastContext'
import { useNavigation } from '@react-navigation/native'
import { useQuery, useQueryClient } from 'react-query'

function Login({ token = '' }) {
  const [loginToken, setLoginToken] = useState(token)
  const navigation = useNavigation()
  const { addToast } = useContext(ToastContext)
  const queryClient = useQueryClient()
  return (
    <>
      <Text style={styles.title}>Bitte gib dein Login-Token ein</Text>
      <TextInput
        style={[styles.input, { marginVertical: 16 }]}
        editable
        placeholder='Login-Token'
        onChangeText={setLoginToken}
        value={loginToken}
        // initialValue={loginToken}
      />
      <Pressable
        style={styles.buttonPrimary}
        onPress={() => {
          getLogin({ token: loginToken })
            .then((data) => {
              console.log(data)
              const { username, password } = data
              setUserData({ username, password }).then(() => {
                queryClient.invalidateQueries()
                addToast({ message: 'Login erfolgreich', type: 'success' })
                // todo: nach Login zum Profil oder direkt auf die Kurse?
                navigation.navigate('HomeTabs', {
                  screen: 'CourseStack',
                  params: { screen: 'Courses' },
                })
              })
            })
            .catch((error) => {
              console.log(error)
              addToast({
                message: 'Es gab einen Fehler mit dem Login-Token',
                type: 'error',
              })
            })
        }}
      >
        <Text style={styles.buttonPrimaryText}>Anmelden</Text>
      </Pressable>
    </>
  )
}

export default Login
