import * as SecureStore from 'expo-secure-store'
import AsyncStorage from '@react-native-async-storage/async-storage'

const getUserData = async () => {
  const username = await AsyncStorage.getItem('username')
  const password = await AsyncStorage.getItem('password')
  return { username, password }
}

const setUserData = async ({ username, password }) => {
  try {
    await AsyncStorage.setItem('username', username)
    await AsyncStorage.setItem('password', password)
  } catch (e) {
    console.log(e)
  }
  return
}

const removeUserData = async () => {
  try {
    await AsyncStorage.removeItem('username')
    await AsyncStorage.removeItem('password')
  } catch (e) {
    console.log(e)
  }
}

export { getUserData, setUserData, removeUserData }
