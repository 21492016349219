import { StyleSheet } from 'react-native'
import {
  boxShadow,
  colors,
  global,
  padding,
  storySafeArea,
} from '../../styles/global'

const styles = StyleSheet.create({
  ...global,
  storyHeader: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  overlayContainer: {
    position: 'absolute',
    top: storySafeArea.top,
    left: 0,
    width: '100%',
    bottom: storySafeArea.bottom,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 20,
    paddingTop: 5,
  },
  storySegmentWrap: {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: '100%',
    alignItems: 'center',
  },
  storyButton: {
    padding: padding.l,
    backgroundColor: '#eee',
    borderRadius: padding.m,
  },
  storyNavButton: {
    width: 48,
    height: 48,
    borderRadius: '100%',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: padding.s,
  },
  infoBox: {
    flex: 1,
    padding: padding.m,
    borderRadius: padding.s,
    backgroundColor: colors.bg,
    marginLeft: padding.s,
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoCircle: {
    backgroundColor: colors.bg,
    borderRadius: padding.s,
    backgroundColor: colors.bg,
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default styles
