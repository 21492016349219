import React from 'react'
import { View, Text, Pressable, Image } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import styles from './styles'
import { colors, padding } from '../../styles/global'

function ListEntry({
  onPress = null,
  onDeletePress = null,
  title,
  thumbnail,
  description,
  numberOfLines = 5,
  type = 'story',
  hasReadStatus = false,
  unread = false,
}) {
  const hasDescription = description && description.length > 0
  const Description = () => {
    return hasDescription ? (
      <View style={{ marginVertical: 4 }}>
        <Text numberOfLines={numberOfLines}>{description}</Text>
      </View>
    ) : null
  }
  return (
    <Pressable
      style={[styles.listEntry, { justifyContent: 'space-between' }]}
      onPress={onPress}
    >
      {hasReadStatus && (
        <View>
          <View
            style={{
              width: 16,
              height: 16,
              borderRadius: 8,
              backgroundColor: unread ? colors.primary : colors.bg,
              borderWidth: 1,
              borderColor: colors.lighter,
              marginRight: padding.m,
            }}
          />
        </View>
      )}
      <View>
        {thumbnail && (
          <Image
            source={{ uri: thumbnail }}
            style={{
              width: 40,
              height: 40,
              borderRadius: 20,
              marginRight: padding.m,
            }}
          />
        )}
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flex: 1 }}>
          <Text style={[styles.titleXL]}>{title}</Text>
          <Description />
        </View>
        {onDeletePress && (
          <Pressable onPress={onDeletePress}>
            <Ionicons name='trash-outline' size={24} color='black' />
          </Pressable>
        )}
      </View>
    </Pressable>
  )
}

export default ListEntry
