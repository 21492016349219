import { MotiView, AnimatePresence } from 'moti'
import React, { useEffect, useState, useRef } from 'react'
import { View, Text } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'

import styles from './styles'
import ToastContext from './ToastContext'
import { colors, padding } from '../../styles/global'

function ToastProvider({ children }) {
  const toasts = useRef({})
  const [render, setRender] = useState(0) // dirty Lösung, um Rerender zu forcen :D
  const addToast = (passedArgs) => {
    const args = { duration: 3000, message: '', type: 'success', ...passedArgs }
    const id = Date.now()
    toasts.current = { ...toasts.current, [id]: { ...args } }
    reRender()
    setTimeout(() => {
      removeToast({ id })
    }, args.duration)
  }
  const removeToast = ({ id }) => {
    const { [id]: _, ...newToasts } = toasts.current
    toasts.current = newToasts
    reRender()
  }
  const addToastByErrorCode = (code) => {
    switch (code) {
      case 401:
        addToast({ type: 'error', message: 'Du bist nicht eingeloggt.' })
        break
      case 404:
        addToast({ type: 'error', message: 'Der Inhalt wurde nicht gefunden.' })
        break
      default:
        break
    }
  }
  const reRender = () => {
    setRender(Date.now())
  }
  const renderToasts = []
  for (const toastId in toasts.current) {
    const toast = toasts.current[toastId]
    const iconName =
      toast.type === 'success' ? 'checkmark-circle' : 'alert-circle'
    const color = toast.type === 'success' ? colors.primary : colors.accent
    renderToasts.push(
      <MotiView
        key={toastId}
        from={{ opacity: 0, translateY: 30, scaleY: 1 }}
        animate={{ opacity: 1, translateY: 0, scaleY: 1 }}
        exit={{ opacity: 0, translateY: -30, scaleY: 0 }}
        style={styles.toast}
      >
        <Ionicons
          name={iconName}
          size={24}
          color={color}
          style={{ marginRight: padding.s }}
        />
        <Text>{toast.message}</Text>
      </MotiView>
    )
  }
  return (
    <ToastContext.Provider value={{ addToast, addToastByErrorCode }}>
      <View style={{ flex: 1, width: '100%' }}>
        {children}
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            alignItems: 'stretch',
          }}
          pointerEvents='none'
        >
          <AnimatePresence>{renderToasts}</AnimatePresence>
        </View>
      </View>
    </ToastContext.Provider>
  )
}

export default ToastProvider
