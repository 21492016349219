import { StyleSheet } from 'react-native'
import { global, padding, colors } from '../../styles/global'

const styles = StyleSheet.create({
  ...global,
  answer: {
    width: '100%',
    backgroundColor: colors.lighter,
    padding: padding.s,
    margin: padding.xs,
    borderRadius: padding.s,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 3,
    borderColor: 'transparent',
  },
  answerCorrect: {
    backgroundColor: '#C5ECC6',
  },
  answerIncorrect: {
    backgroundColor: '#FCE9E8',
  },
  answerPicked: {
    borderColor: colors.primary,
  },
  answerCircle: {
    width: padding.l,
    height: padding.l,
    borderRadius: padding.l / 2,
    borderWidth: 2,
    borderColor: colors.primary,
    marginRight: padding.m,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.bg,
  },
  answerCircleCorrect: {
    backgroundColor: '#4caf50',
    borderWidth: 0,
  },
  answerCircleIncorrect: {
    backgroundColor: '#f44336',
    borderWidth: 0,
  },
  multipleChoice: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: 'white',
    borderRadius: padding.m,
    padding: padding.m,
  },
})

export default styles
