import React, { useRef, useEffect } from 'react'
import { View, Platform, Text } from 'react-native'

function LottieWeb({ source, style, loop = true, autoplay = true }) {
  let Lottie = null
  let LottieView = null
  const isWeb = Platform.OS === 'web'
  if (isWeb) {
    Lottie = require('lottie-web/build/player/lottie_light.min.js')
  } else {
    LottieView = require('lottie-react-native')
  }
  const anim = useRef(null)
  const animContainer = useRef(null)
  useEffect(() => {
    if (isWeb) {
      const anim = Lottie.loadAnimation({
        container: animContainer.current,
        animationData: source,
        autoplay,
        loop,
      })
    } else {
      anim.current?.play()
    }
  }, [])
  // WEB
  if (isWeb) {
    return <View style={style} ref={animContainer}></View>
  }
  // NATIVE
  return <LottieView ref={anim} style={style} source={source} loop />
}

export default LottieWeb
