import React, { useState, useEffect, useContext } from 'react'
import { View, Text, TextInput, Pressable } from 'react-native'
import styles from './styles'
import { colors, padding } from '../../styles/global'

function TableRow({
  headerComponent = null,
  contentComponent = null,
  style = {},
  isLast = false,
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          width: '100%',
          borderColor: colors.border,
          borderBottomWidth: isLast ? 0 : 1,
        },
        style,
      ]}
    >
      <View
        style={{
          minWidth: 180,
          backgroundColor: colors.lighter,
          padding: padding.m,
        }}
      >
        {headerComponent}
      </View>
      <View style={{ flex: 1, justifyContent: 'center', padding: padding.m }}>
        {contentComponent}
      </View>
    </View>
  )
}

export default TableRow
