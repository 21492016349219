import React, { useState, useEffect, useContext } from 'react'
import { View, Text, TextInput, Pressable } from 'react-native'
import styles from './styles'
import LoginView from '../components/LoginView'

function Login({ route }) {
  const token = route.params?.token ?? ''
  return (
    <View style={[styles.container]}>
      <View style={[styles.maxWidth, { padding: 16 }]}>
        <LoginView token={token} />
      </View>
    </View>
  )
}

export default Login
