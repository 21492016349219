import React from 'react'
import { Pressable, Text, View, Image } from 'react-native'
import Svg, { Circle } from 'react-native-svg'
import { useNavigation } from '@react-navigation/native'
import styles from './styles'
import { colors } from '../../styles/global'
import Ionicons from '@expo/vector-icons/Ionicons'

const CIRCLE_RADIUS = 50

function Story({ title, id, thumbnail, progress, isLocked = false }) {
  const navigation = useNavigation()
  const isFinished = parseInt(progress) === 100
  return (
    <Pressable
      style={styles.story}
      onPress={() => {
        isLocked ? null : navigation.navigate('Story', { id })
      }}
    >
      <View>
        <Svg
          height='100'
          width='100'
          viewBox='0 0 100 100'
          style={{
            transformOrigin: 'center',
            transform: [{ rotateZ: '-90deg' }],
          }}
        >
          <Circle
            cx='50'
            cy='50'
            r='48'
            stroke={isFinished || isLocked ? colors.border : colors.accent}
            strokeWidth='4'
            strokeLinecap='round'
            strokeDasharray='0, 0'
            fill='transparent'
          />
        </Svg>
        <Image
          style={{
            position: 'absolute',
            left: 6,
            top: 6,
            width: 88,
            height: 88,
            borderRadius: '100%',
            backgroundColor: 'gray',
            opacity: isLocked ? 0.3 : 1,
          }}
          source={{ uri: thumbnail }}
        />
        {isLocked && (
          <View
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={{ fontSize: 30 }}>🔒</Text>
          </View>
        )}
        {isFinished && (
          <View
            style={{
              width: 32,
              height: 32,
              borderRadius: 16,
              borderWidth: 2,
              borderColor: colors.lighter,
              backgroundColor: colors.primary,
              position: 'absolute',
              bottom: 0,
              right: 0,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Ionicons size={24} name='checkmark' color={colors.bg} />
          </View>
        )}
      </View>
      <Text style={[styles.title, { marginTop: 8 }]}>{title}</Text>
    </Pressable>
  )
}

export default Story
