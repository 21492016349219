import React, { useState, useEffect, useContext, useMemo } from 'react'
import { View, Text, TextInput, ScrollView } from 'react-native'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigation } from '@react-navigation/native'

import { getProfile, getSearch } from '../api/api'
import { removeUserData } from '../api/user'
import styles from './styles'
import ToastContext from '../components/ToastProvider/ToastContext'
import Loading from '../components/Loading'
import Error from '../components/Error'
import { colors, padding } from '../styles/global'
import Table from '../components/Shared/Table'
import Ionicons from '@expo/vector-icons/Ionicons'

import debounce from 'lodash.debounce'
import ListEntry from '../components/Shared/ListEntry'

function Search({}) {
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const resultsEmpty =
    !isLoading && searchResults.length === 0 && searchTerm.length > 0
  const termEmpty = !isLoading && searchTerm.length === 0

  const navigation = useNavigation()

  const handleChange = (e) => {
    const { text } = e.nativeEvent
    const term = encodeURIComponent(text)
    setSearchTerm(term)
  }

  const handleInput = (e) => {
    const { text } = e.nativeEvent
    if (text && text.length > 0) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
      setSearchResults([])
    }
    debouncedResults(e)
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300)
  }, [])

  const getResults = async ({ searchTerm }) => {
    const results = await getSearch({ term: searchTerm })
    setSearchResults(results)
    setIsLoading(false)
  }

  useEffect(() => {
    if (searchTerm !== '') {
      getResults({ searchTerm })
    }
  }, [searchTerm])

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  }, [])

  const resultList = searchResults.map((result, index) => {
    const type = result.post_type
    return (
      <ListEntry
        key={index}
        title={result.title}
        thumbnail={result.thumbnail}
        onPress={() => {
          console.log(result)
          if (type === 'stories') {
            navigation.navigate('Story', {
              id: result.id,
            })
          } else if (type === 'kurse') {
            navigation.navigate('Course', {
              id: result.id,
            })
          }
        }}
      />
    )
  })

  return (
    <>
      <View
        style={{
          width: '100%',
          padding: padding.m,
          borderBottomColor: colors.border,
          borderBottomWidth: 1,
          backgroundColor: colors.bg,
        }}
      >
        <View
          style={{
            backgroundColor: colors.lighter,
            flexDirection: 'row',
            width: '100%',
            borderRadius: 8,
            alignItems: 'center',
          }}
        >
          <View style={{ paddingLeft: padding.s }}>
            <Ionicons name='search' size={24} color={colors.primary} />
          </View>
          <TextInput
            style={{ padding: padding.m, flex: 1 }}
            placeholder='Suche'
            onChange={handleInput}
          />
        </View>
      </View>
      {termEmpty && (
        <View
          style={{ flex: 1, alignContent: 'center', justifyContent: 'center' }}
        >
          <Text style={{ textAlign: 'center' }}>
            Hier könnte man einen "Entdecken"-Screen einbauen.
          </Text>
        </View>
      )}
      {resultsEmpty && (
        <View
          style={{
            flex: 1,
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={{ textAlign: 'center' }}>Nichts gefunden 🤷🏻</Text>
        </View>
      )}
      {isLoading && <Loading />}
      {!isLoading && resultList.length > 0 && (
        <ScrollView style={{ flex: 1 }}>{resultList}</ScrollView>
      )}
    </>
  )
}

export default Search
