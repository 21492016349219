import { StyleSheet } from 'react-native'
import { global, colors, padding, boxShadow } from '../../styles/global'

const styles = StyleSheet.create({
  ...global,
  toast: {
    backgroundColor: colors.bg,
    margin: padding.s,
    padding: padding.m,
    borderRadius: padding.s,
    ...boxShadow.m,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'space-between',
  },
})

export default styles
