import { Video } from 'expo-av'
import { BlurView } from 'expo-blur'
import { LinearGradient } from 'expo-linear-gradient'
import React, { memo } from 'react'
import { Image, View } from 'react-native'
import styles from './styles'

function StoryBackground({
  vidRef,
  onPlaybackStatusUpdate = () => null,
  bgColor,
  img,
  vid,
}) {
  console.log('bg component render')
  const BackgroundComponent = () => {
    if (bgColor) {
      return (
        <View
          style={{
            flex: 1,
            width: '100%',
            backgroundColor: bgColor ?? '#000',
          }}
        />
      )
    } else if (img) {
      const imgLink = img?.sizes?.large
      return (
        <>
          <Image
            source={{ uri: imgLink }}
            style={[styles.absolute, { opacity: 0.5 }]}
          />
          <BlurView intensity={100} tint='dark' style={[styles.absolute]} />
          <Image
            source={{ uri: imgLink }}
            style={{ width: '100%', height: '100%', resizeMode: 'contain' }}
          />
        </>
      )
    } else if (vid) {
      const vidLink = vid?.url
      return (
        <Video
          ref={vidRef}
          style={[styles.absolute]}
          source={{
            uri: vidLink,
          }}
          // useNativeControls
          isMuted={true}
          // shouldPlay={true}
          // onLoad={() => vidRef.current?.pauseAsync()}
          resizeMode='contain'
          // isLooping
          onPlaybackStatusUpdate={onPlaybackStatusUpdate}
        />
      )
    }
    return null
  }

  return (
    <View style={styles.absolute}>
      <BackgroundComponent />
      <LinearGradient
        colors={['rgba(0,0,0,0.3)', 'transparent']}
        style={[styles.absolute, { top: 0, height: 100 }]}
      />
      <LinearGradient
        colors={['transparent', 'rgba(0,0,0,0.3)']}
        style={[styles.absolute, { top: 'auto', bottom: 0, height: 200 }]}
      />
    </View>
  )
}

export default memo(StoryBackground)
