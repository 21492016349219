import React from 'react'
import { View, Text, Pressable, ScrollView, Image } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useQuery, useQueryClient } from 'react-query'

import { getCourses } from '../api/api'
import Loading from '../components/Loading'
import Error from '../components/Error'
import styles from './styles'
import { colors, padding } from '../styles/global'

function Courses({ navigation }) {
  // Queries
  const courses = useQuery('courses', getCourses)

  if (courses.isLoading) {
    return <Loading />
  } else if (courses.isError) {
    const { status } = courses.error?.response
    return <Error code={status} />
  }

  const CourseList = () => {
    return courses.data.length > 0
      ? courses.data?.map((course) => {
          const length = course.length
          const progress = course.progress
          const isCompleted = progress >= length
          const progressBar = []
          for (let i = 0; i < length; i++) {
            const isLast = i === length - 1
            progressBar.push(
              <View
                key={i}
                style={{
                  flex: 1,
                  height: 4,
                  marginTop: 8,
                  marginRight: isLast ? 0 : 8,
                  borderRadius: 2,
                  backgroundColor: i < progress ? colors.primary : colors.bg,
                }}
              />
            )
          }
          return (
            <Pressable
              key={course.id}
              style={[styles.listEntry]}
              onPress={() =>
                navigation.navigate('Course', {
                  id: course.id,
                })
              }
            >
              <View>
                {course.thumbnail && (
                  <Image
                    source={{ uri: course.thumbnail }}
                    style={{
                      width: 48,
                      height: 48,
                      borderRadius: 24,
                      marginRight: padding.m,
                    }}
                  />
                )}
              </View>
              <View style={{ flexDirection: 'column', flex: 1 }}>
                <View
                  style={{
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Text style={styles.titleXL}>{course.title}</Text>
                  {isCompleted && (
                    <Ionicons
                      size={24}
                      name='checkmark-circle'
                      color={colors.accent}
                    />
                  )}
                </View>
                {course.description && (
                  <View style={{ marginVertical: 4 }}>
                    <Text numberOfLines={3}>{course.description}</Text>
                  </View>
                )}
                <View style={{ flexDirection: 'row', width: '100%' }}>
                  {progressBar}
                </View>
              </View>
            </Pressable>
          )
        })
      : null
  }

  return (
    <ScrollView style={{ width: '100%', flex: 1 }}>
      <CourseList />
    </ScrollView>
  )
}

export default Courses
