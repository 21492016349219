import React, { useEffect, useState } from 'react'
import { View, Text, ScrollView, Image } from 'react-native'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getArticle, postProgress } from '../api/api'
import Loading from '../components/Loading'
import Error from '../components/Error'
import styles from './styles'
import { colors, padding } from '../styles/global'

function Article({ navigation, route }) {
  const { id } = route.params
  const queryClient = useQueryClient()

  // Queries
  const article = useQuery(['article', id], () => getArticle({ id }))

  const progressMutation = useMutation(postProgress, {
    onSuccess: () => {
      queryClient.invalidateQueries(['news']) // alle Queries invalidaten, da der neue Progress Auswirkungen auf fast alle Screens haben kann
    },
    onError: (err) => {
      console.log(err)
    },
  })

  useEffect(() => {
    progressMutation.mutate({
      id,
    })
  }, [])

  useEffect(() => {
    if (!article.isLoading)
      navigation.setOptions({ title: article.data?.title })
  }, [article.isLoading])

  // Todo: Loading state
  if (article.isLoading) {
    return <Loading />
  } else if (article.isError) {
    const { status } = article.error?.response
    return <Error code={status} />
  }

  console.log(article.data)

  const Sections = () => {
    const sections = article.data?.sections
    return sections
      ? sections.map((section, index) => {
          const Content = () => {
            const output = []
            section.content?.map((content, index) => {
              const layout = content.acf_fc_layout
              switch (layout) {
                case 'text':
                  output.push(
                    <View key={index} style={{ marginVertical: padding.s }}>
                      <Text>{content.text}</Text>
                    </View>
                  )
                  break
                case 'img':
                  output.push(
                    <View key={index}>
                      <Image
                        source={{ uri: content.img.sizes.large }}
                        style={{ width: '100%', height: 200 }}
                      />
                    </View>
                  )
                  break
                case 'link':
                  output.push(
                    <View
                      key={index}
                      style={{
                        padding: padding.m,
                        backgroundColor: colors.lighter,
                      }}
                    >
                      <Text>Hier ist dein Link (noch nicht implementiert)</Text>
                    </View>
                  )
                  break
                case 'stories':
                  output.push(
                    <View
                      key={index}
                      style={{
                        padding: padding.m,
                        backgroundColor: colors.lighter,
                      }}
                    >
                      <Text>
                        Hier sind deine Stories (noch nicht implementiert)
                      </Text>
                    </View>
                  )
                  break
                case 'themen':
                  output.push(
                    <View
                      key={index}
                      style={{
                        padding: padding.m,
                        backgroundColor: colors.lighter,
                      }}
                    >
                      <Text>
                        Hier sind deine Themen (noch nicht implementiert)
                      </Text>
                    </View>
                  )
                  break
                default:
                  break
              }
            })
            return output
          }
          return (
            <View key={index}>
              <Text style={[styles.title, { marginTop: padding.m }]}>
                {section.title}
              </Text>
              <Content />
            </View>
          )
        })
      : null
  }

  return (
    <ScrollView style={{ flex: 1 }}>
      {article.data.thumbnail && (
        <Image
          source={{ uri: article.data.thumbnail }}
          style={{ width: '100%', height: 300 }}
        />
      )}
      <View style={{ padding: padding.m, backgroundColor: colors.bg }}>
        <Text style={styles.titleXL}>{article.data.title}</Text>
        <Sections />
      </View>
    </ScrollView>
  )
}

export default Article
