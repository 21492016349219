import React from 'react'
import { View } from 'react-native'
import NavBarSegmentVideo from './NavBarSegmentVideo'
import NavBarSegmentStill from './NavBarSegmentStill'

const NavBar = ({
  segments = [],
  setCurrentSegment = null,
  currentSegment = 0,
  currentSegmentProgress,
}) => {
  const bar = []

  segments.forEach((segment, index) => {
    const type = segment.vid ? 'video' : 'still'
    bar.push(
      type === 'video' ? (
        <NavBarSegmentVideo
          key={index}
          index={index}
          currentSegment={currentSegment}
          currentSegmentProgress={currentSegmentProgress}
          setCurrentSegment={setCurrentSegment}
        />
      ) : (
        <NavBarSegmentStill
          key={index}
          index={index}
          currentSegment={currentSegment}
          setCurrentSegment={setCurrentSegment}
        />
      )
    )
  })
  return (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      {bar}
    </View>
  )
}

export default NavBar
