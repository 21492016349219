import { useState, useEffect } from 'react'
import {
  NavigationContainer,
  DefaultTheme,
  useNavigation,
} from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { QueryClient, QueryClientProvider } from 'react-query'
import Ionicons from '@expo/vector-icons/Ionicons'
import ToastProvider from './components/ToastProvider'
import { colors } from './styles/global'
// Screens
import Courses from './screens/Courses'
import Course from './screens/Course'
import Search from './screens/Search'
import Bookmarks from './screens/Bookmarks'
import Profile from './screens/Profile'
import Story from './screens/Story'
import Quiz from './screens/Quiz'
import Login from './screens/Login'
import News from './screens/News'
import Article from './screens/Article'

const Stack = createStackNavigator()
const Tab = createBottomTabNavigator()

const NavTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: colors.primary,
    border: colors.border,
    background: colors.lighter,
    card: colors.bg,
  },
}

const linking = {
  config: {
    screens: {
      Story: { path: 'stories/:id', parse: { id: (id) => id } },
      Quiz: { path: 'quiz/:id', parse: { id: (id) => id } },
      Search: { path: 'suche' },
      NewsStack: {
        screens: {
          News: { path: 'neuigkeiten' },
          Article: { path: 'neuigkeiten/:id', parse: { id: (id) => id } },
        },
      },
      Login: { path: 'login/:token', parse: { token: (token) => token } },
      HomeTabs: {
        screens: {
          CourseStack: {
            screens: {
              Courses: { path: 'themen' },
              Course: { path: 'themen/:id', parse: { id: (id) => id } },
            },
          },
          Bookmarks: { path: 'lesezeichen' },
          ProfileStack: {
            screens: {
              Profile: { path: 'profil' },
            },
          },
        },
      },
    },
  },
}

const modalOptions = {
  presentation: 'modal',
  gestureEnabled: true,
  gestureResponseDistance: 1000, // todo: screenheight hier
  headerMode: false,
}

const HomeTabs = () => (
  <Tab.Navigator
    initialRouteName='CourseStack'
    screenOptions={({ route }) => ({
      tabBarIcon: ({ focused, color, size }) => {
        let iconName

        if (route.name === 'CourseStack') {
          iconName = focused ? 'help-buoy' : 'help-buoy-outline'
        } else if (route.name === 'Bookmarks') {
          iconName = focused ? 'bookmark' : 'bookmark-outline'
        } else if (route.name === 'ProfileStack') {
          iconName = focused ? 'person' : 'person-outline'
        } else if (route.name === 'Search') {
          iconName = focused ? 'search' : 'search-outline'
        } else if (route.name === 'NewsStack') {
          iconName = focused ? 'newspaper' : 'newspaper-outline'
        }

        return <Ionicons name={iconName} size={size} color={color} />
      },
      animationEnabled: true,
      tabBarActiveTintColor: colors.primary,
      tabBarInactiveTintColor: colors.secondary,
      tabBarShowLabel: false,
    })}
  >
    <Tab.Screen
      name='CourseStack'
      options={{ headerShown: false, title: 'Themen' }}
      component={CourseStack}
    />
    <Tab.Screen name='Search' options={{ title: 'Suche' }} component={Search} />
    <Tab.Screen
      name='NewsStack'
      options={{ headerShown: false, title: 'Neuigkeiten' }}
      component={NewsStack}
    />
    <Tab.Screen
      name='Bookmarks'
      options={{ title: 'Lesezeichen' }}
      component={Bookmarks}
    />
    <Tab.Screen
      name='ProfileStack'
      options={{ headerShown: false, title: 'Profil' }}
      component={ProfileStack}
    />
  </Tab.Navigator>
)

const ProfileStack = () => {
  return (
    <Stack.Navigator screenOptions={{ animationEnabled: true }}>
      <Stack.Screen
        name='Profile'
        options={{ title: 'Profil' }}
        component={Profile}
      />
    </Stack.Navigator>
  )
}

const CourseStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{ animationEnabled: true }}
      initialRouteName='Courses'
    >
      <Stack.Screen
        name='Courses'
        options={{ title: 'Themen' }}
        component={Courses}
      />
      <Stack.Screen
        name='Course'
        options={({ route }) => ({ title: 'Thema' })}
        component={Course}
      />
    </Stack.Navigator>
  )
}

const NewsStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{ animationEnabled: true }}
      initialRouteName='News'
    >
      <Stack.Screen
        name='News'
        options={{ title: 'Neuigkeiten' }}
        component={News}
      />
      <Stack.Screen
        name='Article'
        options={({ route }) => ({ title: 'Artikel' })}
        component={Article}
      />
    </Stack.Navigator>
  )
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const { status } = error.response
        return status === 401 ? false : failureCount < 3 // nur Retry, wenn kein Auth Error
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  },
})

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <NavigationContainer
          linking={linking}
          theme={NavTheme}
          detachInactiveScreens
        >
          <Stack.Navigator
            screenOptions={{ headerShown: false, animationEnabled: true }}
            initialRouteName='HomeTabs'
          >
            <Stack.Screen name='HomeTabs' component={HomeTabs} />
            <Stack.Screen
              name='Story'
              component={Story}
              options={modalOptions}
            />
            <Stack.Screen
              name='Quiz'
              component={Quiz}
              options={({ route }) => ({
                title: 'Quiz',
                headerShown: true,
              })}
            />
            <Stack.Screen
              name='Login'
              options={modalOptions}
              component={Login}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </ToastProvider>
    </QueryClientProvider>
  )
}
