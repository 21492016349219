import React from 'react'
import { View } from 'react-native'
import LoadingSpinner from './LoadingSpinner'

function Loading({}) {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <LoadingSpinner
        style={{
          width: 40,
          height: 40,
        }}
      />
    </View>
  )
}

export default Loading
