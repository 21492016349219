import React from 'react'
import { View, Text } from 'react-native'
import { colors, padding } from '../../styles/global'
import styles from './styles'

function Table({ title = '', children }) {
  return (
    <View
      style={{
        width: '100%',
        marginVertical: padding.m,
        alignItems: 'flex-start',
      }}
    >
      {!!title && (
        <View style={{ paddingBottom: padding.m }}>
          <Text style={[styles.title]}>{title}</Text>
        </View>
      )}
      <View
        style={{
          width: '100%',
          borderColor: colors.border,
          borderWidth: 1,
          borderRadius: padding.s,
          overflow: 'hidden',
        }}
      >
        {children}
      </View>
    </View>
  )
}

export default Table
