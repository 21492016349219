import { StyleSheet } from 'react-native'
import { global, padding } from '../../styles/global'

const styles = StyleSheet.create({
  ...global,
  listEntryText: {
    fontSize: 24,
    textAlign: 'left',
  },
  story: {
    alignItems: 'center',
    marginVertical: padding.l,
  },
  quiz: {
    minWidth: 300,
    padding: padding.m,
    borderRadius: padding.m,
    backgroundColor: 'white',
    alignItems: 'center',
    marginVertical: padding.l,
  },
})

export default styles
