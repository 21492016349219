import React, { memo, useEffect, useState } from 'react'
import { Pressable, SafeAreaView, View, Text, Image } from 'react-native'
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated'
import NavBar from './NavBar'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useMutation, useQueryClient } from 'react-query'
import styles from './styles'
import { deleteBookmark, postBookmark } from '../../api/api'
import { colors, padding } from '../../styles/global'
import { MotiView } from 'moti'

function StoryControls({
  id,
  thumbnail,
  segment,
  segments,
  setCurrentSegment,
  currentSegment,
  currentSegmentProgress,
  isPlaying,
  startPlayback,
  pausePlayback,
  segmentIsOver = false,
  goToNextSegment = () => null,
  close,
}) {
  const [isBookmarked, setIsBookmarked] = useState(false)
  const pulseScale = useSharedValue(1)

  const queryClient = useQueryClient()
  // Mutations
  const addBookmark = useMutation(postBookmark, {
    onSuccess: () => {
      queryClient.invalidateQueries(['bookmarks'])
      queryClient.invalidateQueries(['story', id])
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const removeBookmark = useMutation(deleteBookmark, {
    onSuccess: () => {
      queryClient.invalidateQueries(['bookmarks'])
      queryClient.invalidateQueries(['story', id])
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const pulseStyle = useAnimatedStyle(() => {
    return {
      transform: [{ scale: pulseScale.value }],
    }
  }, [segmentIsOver])

  useEffect(() => {
    if (segmentIsOver) {
      console.log('segment is over :)')
      pulseScale.value = withRepeat(
        withTiming(1.2, { duration: 500, easing: Easing.quad }),
        -1,
        true
      )
    } else {
      pulseScale.value = withTiming(1)
    }
  }, [segmentIsOver])

  useEffect(() => {
    setIsBookmarked(segment.is_bookmarked)
  }, [segment.is_bookmarked])

  return (
    <SafeAreaView
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
      }}
      pointerEvents='box-none'
    >
      <View style={styles.storyHeader}>
        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
          {thumbnail && (
            <Image
              style={{
                width: 28,
                height: 28,
                borderRadius: '100%',
                marginRight: 8,
              }}
              source={{ uri: thumbnail }}
            />
          )}
          <Text
            style={[styles.title, { color: 'white', flex: 1 }]}
            numberOfLines={1}
          >
            {segment.title}
          </Text>
        </View>
        <Pressable>
          <Ionicons
            name='close'
            color='white'
            size={30}
            onPress={() => close()}
          />
        </Pressable>
      </View>
      <View
        style={{
          width: '100%',
          padding: 20,
          paddingBottom: 0,
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
          <View style={{ flex: 1, paddingBottom: padding.s }}>
            <Text
              style={[styles.textL, { lineHeight: '1.5', color: colors.bg }]}
            >
              {segment.description}
            </Text>
          </View>
          <View style={{ paddingLeft: padding.m }}>
            {false && ( // Todo: wieder implementieren?
              <Animated.View style={pulseStyle}>
                <Pressable
                  style={styles.storyNavButton}
                  onPress={() => {
                    setAudioEnabled(!audioEnabled)
                    resetPlayback()
                  }}
                >
                  <Ionicons
                    name={
                      // audioEnabled ? 'volume-high-outline' : 'volume-mute-outline'
                      'volume-high-outline'
                    }
                    size={24}
                    color={colors.primary}
                  />
                </Pressable>
              </Animated.View>
            )}
            <Pressable
              style={styles.storyNavButton}
              onPress={() => {
                setIsBookmarked(!isBookmarked)
                const bookmarkAction = isBookmarked
                  ? removeBookmark
                  : addBookmark
                bookmarkAction.mutate({
                  id,
                  data: { index: currentSegment },
                })
              }}
            >
              <Ionicons
                name={isBookmarked ? 'bookmark' : 'bookmark-outline'} // todo: statt false richtige Variable
                size={24}
                color={colors.primary}
              />
            </Pressable>
            <Animated.View style={[pulseStyle]}>
              <Pressable
                style={[
                  styles.storyNavButton,
                  { backgroundColor: colors.primary },
                ]}
                onPress={() => {
                  segmentIsOver
                    ? goToNextSegment()
                    : isPlaying
                    ? pausePlayback()
                    : startPlayback()
                }}
              >
                <Ionicons
                  name={
                    segmentIsOver
                      ? 'play-skip-forward'
                      : isPlaying
                      ? 'pause'
                      : 'play'
                  } // todo: statt false richtige Variable
                  size={24}
                  color={colors.bg}
                />
              </Pressable>
            </Animated.View>
          </View>
        </View>
        <NavBar
          segments={segments}
          setCurrentSegment={setCurrentSegment}
          currentSegment={currentSegment}
          currentSegmentProgress={currentSegmentProgress}
        />
      </View>
    </SafeAreaView>
  )
}

export default memo(StoryControls)
