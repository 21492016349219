import React from 'react'
import { ScrollView } from 'react-native'
import { useQuery, useQueryClient } from 'react-query'
import { getNews } from '../api/api'

import Loading from '../components/Loading'
import Error from '../components/Error'
import ListEntry from '../components/Shared/ListEntry'

function News({ navigation }) {
  // Queries
  const news = useQuery('news', getNews)

  const queryClient = useQueryClient()

  // Todo: Loading state
  if (news.isLoading) {
    return <Loading />
  } else if (news.isError) {
    const { status } = news.error?.response
    return <Error code={status} />
  }

  const NewsList = () => {
    return news.data?.length > 0
      ? news.data?.map((newsPost, index) => {
          return (
            <ListEntry
              key={index}
              onPress={() => {
                navigation.navigate('Article', { id: newsPost.id })
              }}
              hasReadStatus={true}
              {...newsPost}
            />
          )
        })
      : null
  }

  return (
    <ScrollView style={{ width: '100%', flex: 1 }}>
      <NewsList />
    </ScrollView>
  )
}

export default News
