import React, { memo } from 'react'
import { View, Pressable, useWindowDimensions } from 'react-native'
import styles from './styles'
import { useSharedValue } from 'react-native-reanimated'

function FullscreenPressable({
  pausePlayback,
  startPlayback,
  goToNextSegment,
  goToPrevSegment,
}) {
  const pressInTime = useSharedValue(0)
  const screenWidth = useWindowDimensions().width
  return (
    <Pressable
      style={[styles.absolute]}
      onPressIn={({ nativeEvent }) => {
        pausePlayback()
        pressInTime.value = nativeEvent.timestamp
      }}
      onPressOut={({ nativeEvent }) => {
        const pressedFor = nativeEvent.timestamp - pressInTime.value
        const { locationX } = nativeEvent
        if (__DEV__)
          console.log('pressed for: ', pressedFor, 'location', locationX)
        if (pressedFor < 150) {
          if (locationX && locationX < screenWidth / 3) {
            goToPrevSegment()
          } else if (locationX) {
            goToNextSegment()
          }
        } else {
          startPlayback()
        }
      }}
    />
  )
}

export default memo(FullscreenPressable)
