import React, { useEffect, useState, useRef } from 'react'
import { Pressable, Text, View } from 'react-native'
import { AnimatePresence, MotiView } from 'moti'
import Ionicons from '@expo/vector-icons/Ionicons'
import styles from './styles'
import { colors } from '../../styles/global'

const INITIAL_INFO_SCALE = 0

function StoryInfoBox({
  text = '',
  dismissAction = () => null,
  showAction = () => null,
}) {
  const [showInfo, setShowInfo] = useState(true)
  const touched = useRef(false)
  const autoHideDelay = 1000 + text.length * 50

  useEffect(() => {
    console.log('timeout started')
    setTimeout(() => {
      !touched.current ? autoDismiss() : null
      console.log('timeout ended')
    }, autoHideDelay)
  }, [])

  const autoDismiss = () => {
    setShowInfo(false)
    dismissAction()
  }

  const onInfoIconPress = () => {
    touched.current = true // wird auf true gesetzt, sobald interaktion erfolgt ist
    showInfo ? dismissAction() : showAction()
    setShowInfo(!showInfo)
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <AnimatePresence>
        <MotiView
          key='bubble'
          from={{
            opacity: 0,
            scale: INITIAL_INFO_SCALE,
          }}
          animate={{
            opacity: 1,
            scale: [1.2, 1],
          }}
        >
          <Pressable
            hitSlop={20}
            onPress={onInfoIconPress}
            style={styles.infoCircle}
          >
            <Ionicons
              size={24}
              name='information-outline'
              color={colors.primary}
            />
          </Pressable>
        </MotiView>
        {showInfo && (
          <MotiView
            key='textbox'
            style={styles.infoBox}
            from={{
              opacity: 0,
              translateX: -30,
            }}
            animate={{
              opacity: 1,
              translateX: 0,
            }}
            exit={{
              opacity: 0,
              translateX: -30,
            }}
          >
            <Text style={[styles.textL, { marginLeft: 8 }]}>{text}</Text>
          </MotiView>
        )}
      </AnimatePresence>
    </View>
  )
}

export default StoryInfoBox
